<template>
  <panel :title="title" bordered v-if="_items">
    <b-row>
      <b-col md="12" class="mt-3">
        <data-table-local
          id="listOfGuarantee"
          :fields="_fields"
          :items="_items"
          :newable="false"
          :viewable="false"
          :editable="false"
          :deleteable="false"
        />
      </b-col>
    </b-row>
  </panel>
</template>


<script>
export default {
	props: {
		title: String,
		items: null,
	},

	computed: {
		_items() {
			if (this.items.listOfGuarantee) {
				return this.items.listOfGuarantee.map((item) => {
					const {
						amount,
						loan_data: {
							name,
							balance_amount,
							loan_topic: { name: loan_topic_name },
						},
					} = item
					return {
						estimated_amount: this.withCommas(amount),
						loan_data_name: name,
						loan_topic_name,
						balance_amount: this.withCommas(balance_amount),
					}
				})
			} else {
				return []
			}
		},
		_fields() {
			return {
				estimated_amount: {
					label: 'ยอดค้ำ',
				},
				loan_data_name: {
					label: 'ค้ำให้กับ',
				},
				loan_topic_name: {
					label: 'ประเภทเงินกู้',
				},
				balance_amount: {
					label: 'ยอดกู้คงเหลือ',
				},
			}
		},
	},
}
</script>